<template>
    <div class="order_renewal">
        <van-nav-bar title="我的奶卡-续订" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="product">
            <van-card :tag="productInfo.category_label" :price="productInfo.money / 100" :desc="productInfo.intro"
                :title="productInfo.title" :thumb="productInfo.cover" :num="productNum" price-class="product_price"
                custom-class="product_box">
            </van-card>
        </div>
        <div class="order">
            <van-row gutter="20" class="buy_row">
                <van-col span="12">
                    <div class="col_label">
                        <van-icon name="underway-o" /> 续订天数
                    </div>
                </van-col>
                <van-col span="12">
                    <van-stepper v-model="productNum" async-change @change="numConfirm" class="buy_num" />
                </van-col>
            </van-row>

            <van-cell center title="支付方式" icon="balance-o" :value="payTypeValue" is-link @click="showPayTypePopup" />
            <van-popup overlay v-model:show="isPayTypeShow" @close="onClosePayTypePopup" position="bottom">
                <van-picker :columns="payTypeData" title="支付方式" @cancel="onClosePayTypePopup"
                    @confirm="onConfirmPayTypePopup" />
            </van-popup>
            <van-notice-bar mode="closeable" text="支持微信支付、会员卡支付，可切换支付方式" />
        </div>
        <van-divider contentPosition="center"
            customStyle="color: #969799; border-color: #ededed; font-size: 14px;margin-top:50rpx;">
            奶卡信息（续订前）
        </van-divider>
        <div class="cycle">
            <van-cell title="奶卡编号" icon="notes-o" :value="cycleInfo.uuid" />
            <van-cell title="网点名称" icon="shop-o" :value="cycleInfo.store_name" />
            <van-cell title="剩余天数" icon="underway-o" :value="cycleInfo.day_num" />
            <van-cell title="每日数量" icon="logistics" :value="`${cycleInfo.product_day_num+' '+productInfo.unit}`" />
        </div>
        <van-action-bar v-if="showClose === false">
            <van-action-bar-icon icon="chat-o" text="客服" @click="onClickIcon" />
            <van-action-bar-button type="danger" :text="`${'立即支付(' + payMoney + '元)'}`" @click="toPaySubmitDialog" />
        </van-action-bar>
    </div>
    <div class="product_dialog">
        <van-dialog use-slot title="温馨提示" v-model:show="showProductDialog" confirmButtonText="确认支付" cancelButtonText="取消支付"
            show-cancel-button @confirm="toPaySubmit" @close="onProductPayClose" zIndex="999">
            <div class="dialog_box">
                <view class="box_intro">
                续订产品： {{productInfo.title}}
            </view>
            <view class="box_title">
                续订天数： {{productNum}} 天
            </view>
            </div>
        </van-dialog>
    </div>
</template>
<script>
import { onBeforeMount, ref } from 'vue';
import request from '../../utils/request';
import { useRoute, useRouter } from "vue-router";
import { showNotify } from 'vant';
export default {
    setup() {
        const route = useRoute();
        const router = useRouter();
        const onClickLeft = () => history.back();
        const productInfo = ref('');
        const cycleInfo = ref('');
        const productNum = ref(30);
        const payMoney = ref(0);

        const cycleId = ref(0);
        const loadOrderData = () => {
            request({
                url: '/app/user/cycle_renewal_page/' + cycleId.value,
                method: 'GET',
            }).then((res) => {
                if (res) {
                    var payMoneyData = (res.product_info.money / 100) * res.cycle_info.product_day_num * productNum.value;
                    productInfo.value=res.product_info
                    cycleInfo.value=res.cycle_info
                    payMoney.value=payMoneyData
                }
            })
        };
        onBeforeMount(() => {
            cycleId.value = route.query.id;
            if (cycleId.value) {
                loadOrderData()
            }
        });

        const payTypeData = reactive([
            { text: '微信支付', value: '1' },
            { text: '余额支付', value: '2' },
        ]);
        const payTypeValue = ref('微信支付');
        const payTypeIndex = ref('1');
        const isPayTypeShow = ref(false);
        const showPayTypePopup = () => {
            isPayTypeShow.value = true
        };
        const onClosePayTypePopup = () => {
            isPayTypeShow.value = false
        };
        const onConfirmPayTypePopup = ({ selectedOptions }) => {
            isPayTypeShow.value = false;
            payTypeValue.value = selectedOptions[0].text;
            payTypeIndex.value = selectedOptions[0].value;
        };
        const onClickIcon = () => {
            router.push({ name: 'Contact' })
        };
        const numConfirm = (num) => {
            if (!num) {
                productNum.value = 1
            }
            payMoney.value = setPayMoney(productInfo.value.money * num)
        };
        const setPayMoney = (money) => {
            return money / 100;
        };

        const toPaySubmitDialog = () => {
            showProductDialog.value = true
        };
        const showProductDialog = ref(false);
        const toPaySubmit = () => {
            let payType = payTypeIndex.value
            request({
                url: '/app/user/order_renewal_pay',
                method: 'POST',
                data: {
                    data: {
                        product_id: productId.value.id,
                        renewal_buy_day_num: productNum.value,
                        cycle_id: cycleInfo.value.id,
                        pay_type: payType,
                    }
                }
            }, true).then((res) => {
                if (res.status && payType == 2) {
                    //余额支付成功 跳转
                    router.push({ name: 'Order' })
                }
                if (res.status && payType == 1) {
                    payParam.value = res.pay_param;
                    if (payMoney.value == 0) {
                        router.push({ name: 'Order' })
                    } else {
                        if (typeof WeixinJSBridge == "undefined") {
                            if (document.addEventListener) {
                                document.addEventListener(
                                    "WeixinJSBridgeReady",
                                    onBridgeReady,
                                    false
                                );
                            } else if (document.attachEvent) {
                                document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
                                document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
                            }
                        } else {
                            onBridgeReady();
                        }
                    }
                }
            })
        };
        const payParam = ref('');
        const onBridgeReady = () => {
            WeixinJSBridge.invoke(
                "getBrandWCPayRequest",
                {
                    appId: payParam.value.appId, //公众号ID，由商户传入
                    timeStamp: payParam.value.timeStamp, //时间戳，自1970年以来的秒数
                    nonceStr: payParam.value.nonceStr, //随机串
                    package: payParam.value.package,
                    signType: payParam.value.signType, //微信签名方式：
                    paySign: payParam.value.paySign,
                },
                (res) => {
                    console.log(res);
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        // 使用以上方式判断前端返回,微信团队郑重提示：
                        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                        router.push({ name: "Order" });
                    } else {
                        showNotify({
                            message: '支付失败，请稍后再试',
                        })
                    }
                }
            );
        };
        const onProductPayClose = () => {
            showProductDialog.value = false
        };
        return {
            onClickLeft,
            productInfo,
            productNum,
            payTypeData,
            payTypeValue,
            isPayTypeShow,
            payTypeIndex,
            showPayTypePopup,
            onClosePayTypePopup,
            onConfirmPayTypePopup,
            cycleInfo,
            onClickIcon,
            payMoney,
            numConfirm,
            toPaySubmitDialog,
            toPaySubmit,
            onProductPayClose,
            showProductDialog,
        }
    },
}
</script>
<style>
.order_renewal{overflow: hidden;}

.order{width: 100%;}
.order .num_input{color: #07C160;font-size: 18px;}
.order .num_right_icon{color: #07C160;}

.buy_row{padding: 10px 16px;border-bottom: 1px solid #fafafa;font-size: 14px;}
.buy_row .col_label{padding: 5px 0;vertical-align: middle;}
.buy_row .buy_num{float:right;}

.product_dialog {}

.product_dialog .dialog_box {
    padding: 10px;
}

.product_dialog .dialog_box .box_title {
    font-size: 16px;
    color: #ee0a24;
    font-weight: bolder;
    margin-bottom: 10px;
}

.product_dialog .dialog_box .box_address {
    font-size: 12px;
    color: #646566;
    margin-bottom: 10px;
}

.product_dialog .dialog_box .box_intro {
    font-size: 12px;
    color: #646566;
    margin-bottom: 10px;
    text-indent: 2em;
    line-height: 25px;
}

.product_dialog .dialog_box .box_msg {
    font-size: 14px;
    color: #646566;
    margin-bottom: 10px;
    text-indent: 2em;
    line-height: 25px;
}
</style>